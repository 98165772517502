import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CartState } from './cart.state';
import { selectMobilityBudget } from '../user/user.selectors';

export const cartFeature = createFeatureSelector<CartState>('cart');

export const selectCart = createSelector(cartFeature, (state) => state?.cart);

export const selectMobilityDiscountValue = createSelector(
  cartFeature,
  (state) => {
    const hasMobilityBudgetDiscount = state.cart?.discounts?.find(
      (discount: any) =>
        discount?.code?.toLocaleLowerCase?.().startsWith?.('mob_'),
    );
    if (hasMobilityBudgetDiscount?.rule?.value) {
      return hasMobilityBudgetDiscount.rule.value;
    } else {
      return null;
    }
  },
);

export const selectMobilityBudgetDetla = createSelector(
  selectMobilityBudget,
  selectMobilityDiscountValue,
  (mobilityBudget, mobilityDiscountValue: number) => {
    return {
      initialMobilityBudget: mobilityBudget,
      mobilityDiscountValue,
      delta: mobilityBudget ? mobilityBudget - mobilityDiscountValue : null,
    };
  },
);

export const selectCheckoutLoading = createSelector(
  cartFeature,
  (state) => state?.loading,
);
