import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CartActions = createActionGroup({
  source: 'Cart',
  events: {
    'Create Cart': emptyProps(),
    'Create Cart If Not Exists': emptyProps(),
    'Set Cart': props<{
      cart: any;
    }>(),
    'Set Loading': props<{ loading: boolean }>(),
    'Update Item Quantity': props<{
      cartId: string;
      itemId: string;
      quantity: number;
    }>(),
    'Subract Mobility Budget': props<{
      amount: number;
    }>(),
    'Attempt Checkout': props<{
      mode:
        | 'sameAsBillingAddress'
        | 'useShippingAddressId'
        | 'useNewShippingAddress';
      data:
        | string
        | {
            firstName: string;
            lastName: string;
            address1: string;
            address2: string;
            city: string;
            countryCode: string;
            postalCode: string;
          }
        | null;
    }>(),
    'Remove Mobility Budget': emptyProps(),
    'Load Cart': emptyProps(),
    'Add Product To Cart': props<{
      cartId: string;
      itemId: string;
      quantity: number;
    }>(),
  },
});
